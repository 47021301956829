body {
  display: flex;
  min-height: 100vh;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  position: relative;
}

#root {
  width: 100%;   
}

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  -webkit-font-smoothing: antialiased;
  font-stretch: normal;
  font-weight: 600;
  color: rgb(33, 33, 33);
  text-align: center;
  margin: 0px;
  font-size: 22px;
}

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-stretch: normal;
  font-weight: 300;
  color: rgb(33, 33, 33);
  text-align: center;
  margin: 0px;
  font-size: 20px;
}

::-webkit-scrollbar {
  background: transparent;
  height: 5px !important;
  border-radius: 1px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background:#3f51b5;
  height: 5px !important;
  width: 5px;
  border-radius: 1px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 22px;
  }
}

a {
  text-decoration: none;
}


